var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentUser.superAdmin)?_c('div',[_c('div',{staticClass:"w-300px pl-4 pb-4"},[_c('v-combobox',{attrs:{"items":_vm.countries,"label":_vm.$t('commonTable.country'),"item-text":"name","item-value":"id","outline":""},on:{"change":_vm.onChangeCountry},model:{value:(_vm.selectedCountry),callback:function ($$v) {_vm.selectedCountry=$$v},expression:"selectedCountry"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.shoppingEventPosts,"options":_vm.pagination,"server-items-length":_vm.totalShoppingEventPosts,"loading":_vm.loading,"footer-props":{
      showFirstLastPage: true,
      'items-per-page-options': [10, 20, 50, 100]
    }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.productName",fn:function(ref){
    var item = ref.item;
return [_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(item.shopProductPost != null ? item.shopProductPost.title : "")+" ")])]}},{key:"item.shoppingEvent",fn:function(ref){
    var item = ref.item;
return [_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(item.shoppingEvent != null && item.shoppingEvent.donationGoal != null ? item.shoppingEvent.donationGoal.title : "")+" ")])]}},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [(item.status === 'IN_REVIEW')?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"yellow","text-color":"black"}},[_vm._v(" IN REVIEW ")]):_vm._e(),(item.status === 'APPROVED')?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"green","text-color":"white"}},[_vm._v(" APPROVED ")]):_vm._e(),(item.status === 'DENIED')?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"red","text-color":"white"}},[_vm._v(" DENIED ")]):_vm._e()]}},{key:"item.city",fn:function(ref){
    var item = ref.item;
return [(item.shoppingEvent != null && item.shoppingEvent.allCities === true)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"blue darken-1","text-color":"white"}},[_vm._v(" ALL CITIES ")]):_vm._e(),_vm._v(" "+_vm._s(item.shoppingEvent != null && item.shoppingEvent.allCities === false && item.shoppingEvent.cities != null && item.shoppingEvent.cities.length > 0 ? item.shoppingEvent.cities[0].name : "")+" "),_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [(
              item.shoppingEvent != null &&
                item.shoppingEvent.allCities === false &&
                item.shoppingEvent.cities != null &&
                item.shoppingEvent.cities.length > 1
            )?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""}},on),[_vm._v("mdi-dots-horizontal")]):_vm._e()]}}],null,true)},[(item.shoppingEvent != null)?_c('v-list',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"500px"},attrs:{"dense":""}},_vm._l((item.shoppingEvent.cities),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)}),1):_vm._e()],1)]}},{key:"item.date",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseDate(item.date))+" ")]}},{key:"item.clear",fn:function(ref){
            var item = ref.item;
return [(_vm.currentUser.superAdmin || (!_vm.currentUser.superAdmin && !item.allCities))?_c('v-avatar',{attrs:{"color":"green","size":"30","rounded":""}},[_c('router-link',{attrs:{"to":{
            name: 'ShoppingEventPostEdit',
            params: { shoppingEventPostId: item.id }
          }}},[_c('v-icon',{attrs:{"x-large":"","dark":""}},[_vm._v("mdi-eye")])],1)],1):_vm._e()]}},{key:"item.add",fn:function(ref){
          var item = ref.item;
return [(_vm.currentUser.superAdmin || (!_vm.currentUser.superAdmin && !item.allCities))?_c('v-avatar',{attrs:{"color":"red darken-4","size":"26","rounded":""}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){return _vm.delRecord(item)}}},[_vm._v("mdi-delete")])],1):_vm._e()]}},{key:"body.prepend",fn:function(){return [_c('tr',[_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-datetime-picker',{attrs:{"label":_vm.$t('date.from'),"time-format":"HH:mm:ss"},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}}),_c('v-datetime-picker',{attrs:{"label":_vm.$t('date.to'),"time-format":"HH:mm:ss"},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}})],1),_c('td',[_c('v-autocomplete',{attrs:{"items":_vm.userCities,"loading":_vm.citiesIsLoading,"search-input":_vm.search,"clearable":"","item-text":"name","item-value":"id","label":"City"},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveShoppingEventPosts();}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Search for "),_c('strong',[_vm._v("cities")])])],1)]},proxy:true},{key:"item",fn:function(data){return [(typeof data.item !== 'object')?[_c('v-list-item-content',{domProps:{"textContent":_vm._s(data.item)}})]:[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.name)}})],1)]]}}],null,false,2292246050),model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}})],1),_c('td',[_c('v-select',{attrs:{"dense":"","hide-details":"","items":_vm.statusesFilter},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveShoppingEventPosts();}},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-avatar',{attrs:{"color":"indigo","size":"30"}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.page = 1;
                _vm.retrieveShoppingEventPosts();}}},[_vm._v("mdi-magnify ")])],1)],1),_c('td',[_c('v-avatar',{attrs:{"color":"grey","size":"30"}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.page = 1;
                _vm.clearSearch();}}},[_vm._v("mdi-close ")])],1)],1)])]},proxy:true}],null,false,3964703504)}),_c('ConfirmDialog',{ref:"confirm"}),_c('GalleryDialog',{attrs:{"mediaList":_vm.mediaList},on:{"close":function($event){_vm.showGalleryDialog = false}},model:{value:(_vm.showGalleryDialog),callback:function ($$v) {_vm.showGalleryDialog=$$v},expression:"showGalleryDialog"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }