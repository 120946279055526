<template>
  <div v-if="currentUser.superAdmin">
    <div class="w-300px pl-4 pb-4">
      <v-combobox
        :items="countries"
        :label="$t('commonTable.country')"
        item-text="name"
        item-value="id"
        v-model="selectedCountry"
        @change="onChangeCountry"
        outline
      ></v-combobox>
    </div>
    <v-data-table
      :headers="headers"
      :items="shoppingEventPosts"
      :options.sync="pagination"
      :server-items-length="totalShoppingEventPosts"
      :loading="loading"
      :footer-props="{
        showFirstLastPage: true,
        'items-per-page-options': [10, 20, 50, 100]
      }"
      class="elevation-1"
    >
      <template v-slot:item.productName="{ item }">
        <td class="text-xs-right">
          {{ item.shopProductPost != null ? item.shopProductPost.title : "" }}
        </td>
      </template>
      <template v-slot:item.shoppingEvent="{ item }">
        <td class="text-xs-right">
          {{
            item.shoppingEvent != null && item.shoppingEvent.donationGoal != null
              ? item.shoppingEvent.donationGoal.title
              : ""
          }}
        </td>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip class="ma-2" color="yellow" text-color="black" v-if="item.status === 'IN_REVIEW'">
          IN REVIEW
        </v-chip>
        <v-chip class="ma-2" color="green" text-color="white" v-if="item.status === 'APPROVED'">
          APPROVED
        </v-chip>
        <v-chip class="ma-2" color="red" text-color="white" v-if="item.status === 'DENIED'">
          DENIED
        </v-chip>
      </template>
      <template v-slot:item.city="{ item }">
        <v-chip
          class="ma-2"
          color="blue darken-1"
          text-color="white"
          v-if="item.shoppingEvent != null && item.shoppingEvent.allCities === true"
        >
          ALL CITIES
        </v-chip>

        {{
          item.shoppingEvent != null &&
          item.shoppingEvent.allCities === false &&
          item.shoppingEvent.cities != null &&
          item.shoppingEvent.cities.length > 0
            ? item.shoppingEvent.cities[0].name
            : ""
        }}
        <v-menu bottom offset-y>
          <template v-slot:activator="{ on }">
            <v-icon
              v-if="
                item.shoppingEvent != null &&
                  item.shoppingEvent.allCities === false &&
                  item.shoppingEvent.cities != null &&
                  item.shoppingEvent.cities.length > 1
              "
              small
              class="mr-2"
              v-on="on"
              >mdi-dots-horizontal</v-icon
            >
          </template>
          <v-list
            v-if="item.shoppingEvent != null"
            dense
            style="max-height: 500px"
            class="overflow-y-auto"
          >
            <v-list-item v-for="(item, i) in item.shoppingEvent.cities" :key="i">
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:item.date="{ item }">
        {{ parseDate(item.date) }}
      </template>
      <template v-slot:item.clear="{ item }">
        <v-avatar
          v-if="currentUser.superAdmin || (!currentUser.superAdmin && !item.allCities)"
          color="green"
          size="30"
          rounded
        >
          <router-link
            :to="{
              name: 'ShoppingEventPostEdit',
              params: { shoppingEventPostId: item.id }
            }"
          >
            <v-icon x-large dark>mdi-eye</v-icon>
          </router-link>
        </v-avatar>
      </template>
      <template v-slot:item.add="{ item }">
        <v-avatar
          v-if="currentUser.superAdmin || (!currentUser.superAdmin && !item.allCities)"
          color="red darken-4"
          size="26"
          rounded
        >
          <v-icon dark @click="delRecord(item)">mdi-delete</v-icon>
        </v-avatar>
      </template>
      <template v-slot:body.prepend>
        <tr>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-datetime-picker
              :label="$t('date.from')"
              v-model="dateFrom"
              time-format="HH:mm:ss"
            ></v-datetime-picker>
            <v-datetime-picker
              :label="$t('date.to')"
              v-model="dateTo"
              time-format="HH:mm:ss"
            ></v-datetime-picker>
          </td>
          <td>
            <v-autocomplete
              v-model="city"
              :items="userCities"
              :loading="citiesIsLoading"
              :search-input.sync="search"
              clearable
              item-text="name"
              item-value="id"
              label="City"
              @keyup.enter="
                page = 1;
                retrieveShoppingEventPosts();
              "
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    Search for
                    <strong>cities</strong>
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </td>
          <td>
            <v-select
              v-model="status"
              dense
              hide-details
              :items="statusesFilter"
              @keyup.enter="
                page = 1;
                retrieveShoppingEventPosts();
              "
            ></v-select>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-avatar color="indigo" size="30">
              <v-icon
                dark
                @click="
                  page = 1;
                  retrieveShoppingEventPosts();
                "
                >mdi-magnify
              </v-icon>
            </v-avatar>
          </td>
          <td>
            <v-avatar color="grey" size="30">
              <v-icon
                dark
                @click="
                  page = 1;
                  clearSearch();
                "
                >mdi-close
              </v-icon>
            </v-avatar>
          </td>
        </tr>
      </template>
    </v-data-table>
    <ConfirmDialog ref="confirm" />
    <GalleryDialog
      v-model="showGalleryDialog"
      @close="showGalleryDialog = false"
      :mediaList="mediaList"
    />
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import CountryService from "@/core/services/country.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ConfirmDialog from "@/view/pages/ConfirmDialog";
import { mapGetters } from "vuex";
import moment from "moment";
import GalleryDialog from "@/view/pages/GalleryDialog";

export default {
  name: "shoppingEventPosts",
  computed: {
    ...mapGetters(["currentUser"]),
    name() {
      return this.$i18n.t("commonTable.shoppingEventPosts");
    },
    headers() {
      return [
        { text: "Id", align: "start", sortable: true, value: "id" },
        { text: this.$i18n.t("commonTable.shoppingEvent"), value: "shoppingEvent" },
        { text: this.$i18n.t("pages.shopPosts.productName"), value: "productName" },
        { text: this.$i18n.t("commonTable.date"), value: "date" },
        { text: this.$i18n.t("commonTable.city"), value: "city" },
        { text: this.$i18n.t("commonTable.status"), value: "status" },
        { text: this.$i18n.t("commonTable.quantity"), value: "quantity" },
        { text: "", value: "search", width: "5%" },
        { text: "", value: "clear", width: "5%" }
      ];
    },
    statusesFilter() {
      return ["IN_REVIEW", "APPROVED", "DENIED"];
    }
  },
  data() {
    return {
      dateFrom: null,
      dateTo: null,
      totalShoppingEventPosts: 0,
      shoppingEventPosts: [],
      userCities: [],
      citiesIsLoading: false,
      city: "",
      search: "",
      loading: true,
      pagination: {},
      citiesList: [],
      errors: [],
      mediaList: [],
      showGalleryDialog: false,
      status: "IN_REVIEW",
      shoppingEventId: null,
      countries: [],
      selectedCountry: null
    };
  },
  watch: {
    pagination: {
      handler() {
        this.retrieveShoppingEventPosts();
      },
      deep: true
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.name }]);
  },
  components: {
    ConfirmDialog,
    GalleryDialog
  },
  created() {
    this.shoppingEventId = this.$route.params.shoppingEventId;
    this.searchCities();
    this.getCountries();
  },
  methods: {
    getCountries() {
      this.loading = true;
      CountryService.getCountries()
        .then(res => {
          if (!res?.length) return;
          this.countries = res;
          const activeCountryId = CountryService.getActiveCountry();
          this.selectedCountry = this.countries.find(item => item.id === activeCountryId);
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    onChangeCountry() {
      this.retrieveShoppingEventPosts();
      this.searchCities();
    },
    async delRecord(item) {
      if (
        await this.$refs.confirm.open(
          this.$i18n.t("common.confirm"),
          this.$i18n.t("pages.recommendedCategories.deleteCatMessage") + item.id + " ?"
        )
      ) {
        this.deleteRecord(item);
      }
    },
    goToGallery(mediaList) {
      this.showGalleryDialog = true;
      this.mediaList = mediaList;
    },
    parseDate(date) {
      return moment
        .utc(date)
        .local()
        .format("yyyy-MM-DD HH:mm:ss");
    },
    deleteRecord(item) {
      this.deleteShoppingEventPosts(item.id);
    },
    searchCities() {
      // Items have already been requested
      if (this.citiesIsLoading) return;

      const params = {};
      params["countryId"] = this.selectedCountry?.id || ""

      this.citiesIsLoading = true;
      ApiService.query("api/cities", { params })
        .then(res => {
          this.userCities = res.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.citiesIsLoading = false));
    },
    deleteShoppingEventPosts(shoppingEventPost) {
      this.loading = true;
      ApiService.delete(`api/shopping-event-post/${shoppingEventPost}`)
        .then(response => {
          this.$log.debug("Shopping event post deleted: ", response);
          this.retrieveShoppingEventPosts();
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    getRequestParams(city, dateFrom, dateTo, status, shoppingEventId, selectedCountry) {
      let params = {};

      if (city) {
        params["city"] = city;
      }

      if (dateFrom) {
        params["dateFrom"] = dateFrom;
      }

      if (dateTo) {
        params["dateTo"] = dateTo;
      }

      if (status) {
        params["status"] = status;
      }

      if (shoppingEventId) {
        params["shoppingEventId"] = shoppingEventId;
      }

      if (selectedCountry) {
        params["countryId"] = selectedCountry.id;
      }

      let sort;
      let sortBy = this.pagination.sortBy;
      let sortDesc = this.pagination.sortDesc;
      if (sortBy.length === 1 && sortDesc.length === 1) {
        sort = sortBy[0];
        if (sortDesc[0] === true) sort += ",desc";
      }
      params["sort"] = sort;
      params["page"] = this.pagination.page;
      params["size"] = this.pagination.itemsPerPage;

      return params;
    },
    clearSearch() {
      this.city = "";
      this.dateFrom = null;
      this.dateTo = null;
    },
    retrieveShoppingEventPosts() {
      const params = this.getRequestParams(
        this.city,
        this.dateFrom,
        this.dateTo,
        this.status,
        this.shoppingEventId,
        this.selectedCountry
      );
      return new Promise(resolve => {
        this.loading = true;
        ApiService.query("api/shopping-event-post", {
          params
        }).then(response => {
          // JSON responses are automatically parsed.
          this.$log.debug("Shopping event posts: ", response.data.content);
          this.shoppingEventPosts = response.data.content;
          this.totalShoppingEventPosts = response.data.totalElements;
          this.totalPages = response.data.totalPages;
          this.loading = false;
          let title = this.totalShoppingEventPosts != null ? this.totalShoppingEventPosts : 0;
          this.$store.dispatch(SET_BREADCRUMB, [{ title: `${this.name}: ` + title }]);
          resolve();
        });
      });
    }
  }
};
</script>

<style>
.v-data-table.v-data-table.v-data-table >>> td {
  font-size: 16px;
}

.v-icon.v-icon {
  font-size: 24px !important;
}

tbody tr:nth-of-type(even) {
  background-color: rgba(236, 237, 237);
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(250, 250, 250);
}
</style>
